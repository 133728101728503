<template>
  <section class="news" v-if="articles">
    <router-link
      :key="article.ItemID"
      :to="article.url"
      class="latest shadow"
      v-for="article in articles.slice(0, limit)"
    >
      <span
        :style="{ backgroundImage: 'url(' + article.image + ')' }"
        aria-label="title"
        class="img"
      ></span>
      <span class="item-content">
        <span class="title">{{ article.title }}</span>
        <span class="summary">{{ article.description }}</span>
        <span class="link">Read more</span>
      </span>
    </router-link>
  </section>
</template>

<script>
export default {
  props: ["limit", "articles"]
}
</script>

<style scoped>
@media screen and (max-width: 500px) {
}
</style>
