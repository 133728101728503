<template>
  <section class="content">
    <div class="text">
      <div class="breadcrumb" v-if="page.ParentID == 'nL7MIVtBq'">
        <router-link to="/insurance"> <font-awesome-icon class="icon" icon="chevron-left" />back </router-link>
      </div>
      <div class="breadcrumb" v-if="page.ParentID == '2_wGuGXUE'">
        <router-link to="/healthcare"> <font-awesome-icon class="icon" icon="chevron-left" />back </router-link>
      </div>
      <div class="breadcrumb" v-if="page.ParentID == '156qiMGPW' || $route.name == 'article'">
        <router-link to="/news"> <font-awesome-icon class="icon" icon="chevron-left" />back </router-link>
        <h1>{{ page.name }}</h1>
      </div>

      <img :alt="page.name" :src="page.image" class="rimg" v-if="page.image" />
      <span v-html="page.main_text"></span>

      <template v-if="$route.name == 'about' && page.team && page.team.length">
        <h2>Meet the Team</h2>
        <profiles-elem :profiles="page.team"></profiles-elem>
      </template>

      <template v-if="$route.name == 'rep' && page.reps && page.reps.length">
        <h2>Your Representatives</h2>
        <profiles-elem :profiles="page.reps" class="reps"></profiles-elem>
      </template>

      <benefits-elem v-if="$route.name == 'insurance-benefits'"></benefits-elem>
      <faqs-elem v-if="$route.name == 'faqs'"></faqs-elem>
      <regs-elem v-if="$route.name == 'regs'"></regs-elem>
      <latest-elem :articles="$store.state.articles" :limit="10000" v-if="$route.name == 'news'"></latest-elem>

      <div class="form" v-if="$route.name == 'healthcare-apply' || $route.name == 'insurance-apply'">
        <div class="form-row">
          <label class="form-label">Name</label>
          <input class="form-text" type="text" v-model="form.name" />
        </div>
        <div class="form-row">
          <label class="form-label">Collar Number</label>
          <input class="form-text" type="text" v-model="form.collar" />
        </div>
        <div class="form-row">
          <label class="form-label">Email</label>
          <input class="form-text" type="email" v-model="form.email" />
        </div>
        <div class="form-error" v-show="errormessage">{{ errormessage }}</div>
        <button @click="sendEmail" class="button">SUBMIT</button>
        <div class="form-success" v-show="emailsuccess">Thanks, your request has been sent!</div>
      </div>
    </div>

    <div class="text dl">
      <downloads />
    </div>
  </section>
</template>

<script>
import api from "@/services/api"
import ProfilesElem from "@/components/Profiles.vue"
import FaqsElem from "@/components/Faqs.vue"
import RegsElem from "@/components/Regs.vue"
import LatestElem from "@/components/Latest.vue"
import BenefitsElem from "@/components/Benefits.vue"

export default {
  data: function() {
    return {
      emailsuccess: false,
      errormessage: "",
      form: {},
      initial_values: {}
    }
  },
  metaInfo() {
    return {
      title: this.$store.state.page.title || "South Yorkshire Police Federation",
      meta: [
        { name: "description", content: this.$store.state.page.meta_desc },
        { name: "keywords", content: this.$store.state.page.meta_keywords }
      ]
    }
  },
  components: {
    ProfilesElem,
    FaqsElem,
    RegsElem,
    LatestElem,
    BenefitsElem
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.initial_values))
    if (this.$route.name == "article") this.$store.commit("setPageByArticleID", this.$route.params.id)
    else api.getPageByURL(this.$route.path)
  },
  computed: {
    page() {
      return this.$store.state.page
    }
  },
  methods: {
    sendEmail: function() {
      this.emailsuccess = false
      this.errormessage = ""

      if (!this.form.name || !this.form.email) {
        this.errormessage = "Please fill in all required fields, thanks!"
        return false
      }
      var subject = `Group Insurance Scheme Application`
      var content = `The following person would like to join the Group Insurance scheme:<br /><br />`

      if (this.$route.name == "healthcare-apply") {
        subject = `Healthcare Scheme Application`
        content = `The following person would like to join the Healthcare scheme:<br /><br />`
      }

      if (this.form.name) content += `Name: ${this.form.name}<br />`
      if (this.form.collar) content += `Collar Number: ${this.form.collar}<br />`
      if (this.form.email) content += `Email: ${this.form.email}<br />`

      api
        .sendEmail(subject, content)
        .then(() => {
          this.emailsuccess = true
          this.form = JSON.parse(JSON.stringify(this.initial_values))
        })
        .catch(() => {
          this.errormessage = "There has been an error submitting your message"
          this.emailerror = true
        })
    }
  }
}
</script>

<style scoped>
.content >>> .rimg {
  float: right;
  max-width: 30%;
  margin-left: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
}

.dl {
  margin-top: -5rem;
}

@media screen and (max-width: 1080px) {
  .content >>> img {
    height: auto !important;
  }
}

@media screen and (max-width: 600px) {
  .content >>> .rimg {
    float: none;
    max-width: 100%;
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
  }
}
</style>
