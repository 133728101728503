<template>
  <section class="faqs">
    <template v-for="faq in page.faqs">
      <a
        :key="faq._id"
        href
        @click.prevent="faq_to_show == faq._id ? (faq_to_show = 0) : (faq_to_show = faq._id)"
        class="question"
        :class="faq_to_show == faq._id ? 'sel' : ''"
      >
        <font-awesome-icon class="icon" icon="question" />
        <span>{{ faq.question }}</span>
      </a>
      <div :key="faq._id + 'answer'" class="answer" v-html="faq.answer" v-if="faq_to_show == faq._id"></div>
    </template>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      faq_to_show: 0,
      faqs: [
        {
          id: 1,
          question: "I am being served a regulation notice, do I need anyone present?",
          answer:
            "<p>There is no obligation to respond or say anything at the service of notice, the federation would not normally attend, you may decide to ask a federation representative or supervisor to be present where the IOPC (formerly IPCC) are serving the notice. We recommend however, that you do make early contact with a suitably trained federation representative.</p>"
        },
        {
          id: 2,
          question: "My notice states I have 10 days to respond or inferences may be drawn, what should I do?",
          answer:
            "<p>There is no obligation to respond or say anything at the service of notice, the federation would not normally attend, you may decide to ask a federation representative or supervisor to be present where the IOPC (formerly IPCC) are serving the notice. We recommend however, that you do make early contact with a suitably trained federation representative.</p>"
        },
        {
          id: 3,
          question: "I have been notified this is a proportionate investigation & asked to respond in writing, why?",
          answer:
            "<p>There is no obligation to respond or say anything at the service of notice, the federation would not normally attend, you may decide to ask a federation representative or supervisor to be present where the IOPC (formerly IPCC) are serving the notice. We recommend however, that you do make early contact with a suitably trained federation representative.</p>"
        },
        {
          id: 4,
          question: "What can I expect from my federation representative?",
          answer:
            "<p>There is no obligation to respond or say anything at the service of notice, the federation would not normally attend, you may decide to ask a federation representative or supervisor to be present where the IOPC (formerly IPCC) are serving the notice. We recommend however, that you do make early contact with a suitably trained federation representative.</p>"
        },
        {
          id: 5,
          question: "I am being served a regulation notice, do I need anyone present?",
          answer:
            "<p>There is no obligation to respond or say anything at the service of notice, the federation would not normally attend, you may decide to ask a federation representative or supervisor to be present where the IOPC (formerly IPCC) are serving the notice. We recommend however, that you do make early contact with a suitably trained federation representative.</p>"
        },
        {
          id: 6,
          question: "My notice states I have 10 days to respond or inferences may be drawn, what should I do?",
          answer:
            "<p>There is no obligation to respond or say anything at the service of notice, the federation would not normally attend, you may decide to ask a federation representative or supervisor to be present where the IOPC (formerly IPCC) are serving the notice. We recommend however, that you do make early contact with a suitably trained federation representative.</p>"
        },
        {
          id: 7,
          question: "I have been notified this is a proportionate investigation & asked to respond in writing, why?",
          answer:
            "<p>There is no obligation to respond or say anything at the service of notice, the federation would not normally attend, you may decide to ask a federation representative or supervisor to be present where the IOPC (formerly IPCC) are serving the notice. We recommend however, that you do make early contact with a suitably trained federation representative.</p>"
        },
        {
          id: 8,
          question: "What can I expect from my federation representative?",
          answer:
            "<p>There is no obligation to respond or say anything at the service of notice, the federation would not normally attend, you may decide to ask a federation representative or supervisor to be present where the IOPC (formerly IPCC) are serving the notice. We recommend however, that you do make early contact with a suitably trained federation representative.</p>"
        }
      ]
    }
  },
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>

<style scoped>
.faqs {
  padding: 5rem 0;
  font-size: 1.8rem;
  line-height: 1.8;
  padding-top: 3rem;
}
.question {
  display: table;
  width: 100%;
  background: #eee;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  color: #203165;
  text-decoration: none;
  font-weight: bold;
  line-height: 1.3;
  transition: all 0.3s;
}
.question.sel {
  background: #203165;
  color: white;
}
.question svg {
  margin-right: 1rem;
  color: white;
  border-radius: 100%;
  padding: 0.5rem;
  background: #db411d;
  width: 2rem;
  height: 2rem;
  position: relative;
  float: left;
  transition: all 0.3s;
  margin-top: 0.5rem;
}

.question.sel svg {
  background: #febd51;
  color: #203165;
}
.question span {
  position: relative;
  float: left;
  width: calc(100% - 5rem);
  padding: 0.5rem;
}
.answer {
  padding: 0 2rem 4rem 2rem;
}

@media screen and (max-width: 500px) {
}
</style>
