<template>
  <section class="profiles">
    <div class="grid">
      <a href :key="benefit._id" @click.prevent="showbenefit(benefit)" v-for="benefit in page.benefits">
        <div class="img">
          <img :src="benefit.image" alt="Name" />
        </div>
        <div class="caption">
          <span class="profile-name">{{ benefit.name }}</span>
        </div>
      </a>
    </div>

    <modal :scrollable="true" height="auto" name="benefits" width="90%">
      <a @click.prevent="$modal.hide('benefits')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <div class="modal-grid">
        <div>
          <h2>{{ benefitToShow.name }}</h2>
          <div v-html="benefitToShow.description"></div>

          <button class="button" v-if="benefitToShow.file" @click="openDoc(benefitToShow.file)">
            <font-awesome-icon class="icon" icon="cloud-download-alt" />DOWNLOAD LEAFLET
          </button>
        </div>
        <div class="images">
          <img :src="benefitToShow.image" :alt="benefitToShow.name" />
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
export default {
  data: function() {
    return {
      benefitToShow: {},
      benefits: [
        {
          id: 1,
          name: "Critical Illness Cover",
          image: "icon.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 2,
          name: "GP24",
          image: "icon.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 3,
          name: "Worldwide Travel Policy",
          image: "icon.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 4,
          name: "Legal Expenses",
          image: "icon.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 5,
          name: "Home Emergency Assistance",
          image: "icon.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 6,
          name: "Motor Breakdown",
          image: "icon.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 7,
          name: "Mobile Phone Cover",
          image: "icon.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        }
      ]
    }
  },
  methods: {
    showbenefit(benefit) {
      this.benefitToShow = benefit
      this.$modal.show("benefits")
    },
    openDoc(doc) {
      window.open(doc)
    }
  },
  computed: {
    page() {
      return this.$store.state.page
    }
  }
}
</script>

<style scoped>
.profiles {
  padding: 5rem 0;
  font-size: 1.8rem;
  line-height: 1.8;
  padding-top: 3rem;
}
.img {
  background: white;
  display: flex;
  align-items: center;
  height: 20rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.img img {
  display: block;
  margin: auto;
  max-height: 80%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
}
.modal-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 5rem;
  padding-bottom: 3rem;
}
.modal-grid a {
  color: #db411d;
}
.images {
  padding-top: 9rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modal-grid {
    display: table;
    width: 100%;
  }
  .modal-grid > * {
    float: left;
    width: 67%;
    margin-right: 3%;
  }
  .modal-grid > *:last-child {
    width: 30%;
    margin-right: 0;
  }
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    float: left;
    width: 22%;
    margin-right: 3%;
    margin-bottom: 5rem;
  }
}

.grid a {
  position: relative;
  line-height: 0;
  text-decoration: none;
}

.grid a img {
  transition: all 0.3s;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.caption {
  width: 100%;
  background-color: #203165;
  line-height: 1.2;
  color: white;
  padding: 2rem;
  transition: all 0.3s;
  text-align: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.grid a:hover .caption {
  background-color: #db411d;
}

.caption span {
  display: block;
}

.profile-title {
  opacity: 0.5;
}

.grid a:hover img {
  filter: brightness(1.05);
}

@media screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .images {
    padding-top: 4.5rem;
  }
}
@media screen and (max-width: 850px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .modal-grid {
    grid-template-columns: 1fr;
    grid-gap: rem;
  }
  .images {
    padding-top: 0;
  }
}
@media screen and (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
