<template>
  <section class="profiles">
    <div class="grid">
      <a href :key="profile.id" @click.prevent="showProfile(profile)" v-for="profile in profiles">
        <img :src="profile.image" v-if="profile.image" :alt="profile.name" />
        <div class="caption">
          <span class="profile-name">{{ profile.name }}</span>
          <span v-if="profile.title" class="profile-title">{{ profile.title }}</span>
          <span v-if="profile.location" class="profile-title">{{ profile.location }}</span>
        </div>
      </a>
    </div>

    <modal :scrollable="true" height="auto" name="profile" width="90%">
      <a @click.prevent="$modal.hide('profile')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <div class="modal-grid">
        <div>
          <h2>{{ profileToShow.name }}</h2>
          <p v-if="profileToShow.title">
            <strong>{{ profileToShow.title }}</strong>
          </p>
          <p v-if="profileToShow.location">
            <strong>{{ profileToShow.location }}</strong>
          </p>
          <div v-html="profileToShow.bio"></div>
          <button class="button" @click="openEmail(profileToShow.email)" v-if="profileToShow.email">
            <font-awesome-icon class="icon" icon="envelope" />SEND A MESSAGE
          </button>
        </div>
        <div class="images">
          <img :src="profileToShow.image" v-if="profileToShow.image" :alt="profileToShow.name" />
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
export default {
  props: ["profiles"],
  data: function() {
    return {
      profileToShow: {}
    }
  },
  methods: {
    showProfile(profile) {
      this.profileToShow = profile
      this.$modal.show("profile")
    },
    openEmail(email) {
      window.open("mailto:" + email)
    }
  }
}
</script>

<style scoped>
.profiles {
  padding: 5rem 0;
  font-size: 1.8rem;
  line-height: 1.8;
  padding-top: 3rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
}
.modal-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 5rem;
  padding-bottom: 3rem;
}
.images {
  padding-top: 9rem;
}
.images img {
  border-radius: 1rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modal-grid {
    display: table;
    width: 100%;
  }
  .modal-grid > * {
    float: left;
    width: 67%;
    margin-right: 3%;
  }
  .modal-grid > *:last-child {
    width: 30%;
    margin-right: 0;
  }
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    float: left;
    width: 22%;
    margin-right: 3%;
    margin-bottom: 5rem;
  }
}

.grid a {
  position: relative;
  line-height: 0;
  text-decoration: none;
  background-color: #203165;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.grid a img {
  transition: all 0.3s;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.caption {
  width: 100%;
  line-height: 1.2;
  color: white;
  padding: 2rem;
  transition: all 0.3s;
  text-align: center;
}

.reps .caption {
  border-radius: 1rem;
}

.grid a:hover {
  background-color: #db411d;
}

.caption span {
  display: block;
}

.profile-title {
  opacity: 0.5;
}

.grid a:hover img {
  filter: brightness(1.05);
}

@media screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .images {
    padding-top: 4.5rem;
  }
}
@media screen and (max-width: 850px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .modal-grid {
    grid-template-columns: 1fr;
    grid-gap: rem;
  }
  .images {
    padding-top: 0;
  }
}
@media screen and (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
